<template>
  <v-chip-group column color="white" class="pa-0">
    <v-chip style="width: 100%; height: 64px; background-color: white !important;"
            v-for="(subject, idx) in trends_topics" bottom
            @click="routerPush(subject)" :ripple="false"
            class="d-flex shadow-blue rounded py-4 px-2 ml-1 no-background-hover my-2"
    >
      <v-divider class="mr-1"></v-divider>
      <v-icon size="22" color="primary" class="mr-2">
        {{ $vuetify.icons.values[subject.subject_icon] }}
      </v-icon>
      <div class="d-inline-block text-truncate">
      <span class="font-weight-medium" style="font-size: 16px; color: rgba(37, 38, 94, 0.7) !important">
          {{ subject.subject_name.charAt(0).toUpperCase() + subject.subject_name.slice(1).toLowerCase() }}
            </span>

      <span class="ml-1" style="font-size: 15px; color: rgba(37, 38, 94, 0.7) !important">
          {{ subject.topic_name }}
            </span>
      </div>
    </v-chip>
  </v-chip-group>
</template>

<script>
import controller from "../../../store/Controller";

export default {
  name: "TopAssignatura",
  props: {
    topics: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loading: false,
      trends_subjects: [],
      trends_topics: []
    }
  },
  async mounted() {
    if (this.topics)
      await this.fetchTrendsTopics();
    else
      await this.fetchTrendsSubjects();
  },
  methods: {
    goToAssig(assig) {
      this.$router.push({name: 'Subject', params: {subject_name: assig}})
    },
    async fetchTrendsSubjects() {
      this.loading = true;
      try {
        const {data} = await this.axios.get('/trends/subjects')
        this.trends_subjects = data;
      } finally {
        this.loading = false;
      }
    },
    async fetchTrendsTopics() {
      this.loading = true;
      try {
        const {data} = await this.axios.get('/trends/topics')
        this.trends_topics = data.slice(0, 3);
      } finally {
        this.loading = false;
      }
    },
    routerPush(subject) {
      if (subject.topic_id) {
        this.$router.push('/selectivitat/' + subject.subject_name + '/per-temes/' + subject.topic_path)
      } else {
        this.$router.push('/selectivitat/' + subject.subject_name)
      }
    }
  }
}
</script>

<style scoped>
.shadow-blue {
  box-shadow: 0 3px 4px rgb(2 30 84 / 10%) !important;
  border-top: #256EFF 3px solid;
}
</style>
