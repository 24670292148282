<template>
  <div style="background: white" class="pb-12">

    <v-container class="py-12" style="max-width: 1300px !important;">
      <!---------------------------------->
      <!--   Title                      -->
      <!---------------------------------->
      <v-card flat class="rounded-lg pt-12 shadow-blue"
              style="z-index: 4 !important;">
        <div class="d-flex px-6 align-center">
          <v-card flat color="#CFDDFD" height="50" width="50" class="rounded-circle d-flex mr-3">
            <v-icon color="#5A8CF8" style="font-size: 30px !important;" class="mx-auto">{{mdiSchool}}</v-icon>
          </v-card>
          <h1>Blog de la Selectivitat</h1>
          <v-spacer></v-spacer>
          <v-btn
            v-if="$root.currentUser && $root.currentUser.isAdmin()"
            @click="addArticle"
            class="text-none px-4"
                 color="primary"
                 rounded outlined
                 style="border: thin solid !important;"
          >
            Nou article
          </v-btn>
        </div>


        <v-divider class="mt-12"></v-divider>


        <!---------------------------------->
        <!--   Tab selector               -->
        <!---------------------------------->
        <div style="width: 100%; height: 100%" class="pb-6 pt-3">
          <v-tabs
            v-model="tabModel"
            color="#333"
            background-color="transparent"
            class="blog-tabs px-6"
          >
            <v-tab
              v-for="(tab, n) in tabs"
              :key="n"
              :ripple="false"
              class="text-none no-background-hover px-0 mr-3"
            >
              {{ tab.name }}
            </v-tab>
          </v-tabs>
        </div>
      </v-card>


      <v-row class="mt-5" style="position: relative">
        <v-col cols="12" md="9">

          <!---------------------------------->
          <!--   Article List               -->
          <!---------------------------------->
          <article-component
            v-for="article in articles"
            :article="article"
            class="mb-5" style="z-index: 4 !important; "
          ></article-component>


        </v-col>

        <v-col cols="12" md="3"  style="position: sticky !important; position: -webkit-sticky; top: 0; left: 0">
          <div class="d-flex flex-column" style="position: sticky !important; top: 20px; left: 0">
            <span class="font-weight-medium" style="font-size: 18px; color: #0f2e65">Estudia de forma intel·ligent amb Examenselectivitat</span>
            <span style="font-size: 16px" class="grey--text text--darken-2 mt-1">La selectivitat ordenada per temes</span>

            <trending-topics class="mb-3"></trending-topics>

<!--            <span class="font-weight-medium" style="font-size: 18px; color: #0f2e65">-->
<!--              Articles més llegits-->
<!--            </span>-->


          </div>
        </v-col>

      </v-row>

    </v-container>
  </div>
</template>

<script>
import {mdiSchool} from '/src/assets/mdi.json'

import ArticleComponent from "./components/ArticleComponent";
import TrendingTopics from "./components/TrendingTopics";

export default {
  name: "BlogPage",
  metaInfo() {
    return {
      title: `El Blog de la Selectivitat`,
      titleTemplate: '%s - examenselectivitat',
      meta: [
        {charset: 'utf-8'},
        {name: 'description', content: this.subtitle},
        {name: 'viewport', content: 'width=device-width, initial-scale=1'}
      ]
    }
  },
  components: {ArticleComponent, TrendingTopics},
  beforeMount() {
    this.fetchArticles();
  },
  mounted() {
    this.infiniteScrollArticles();
  },
  data() {
    return {
      articles: [],
      mdiSchool: mdiSchool,
      tabModel: null,
      tabs: [
        {
          name: "En tendència",
          values: []
        },
        {
          name: "Els últims",
          values: []
        },
        {
          name: "Els millors",
          values: []
        }
      ]
    }
  },
  methods: {
    async infiniteScrollArticles() {
      window.onscroll = async () => {
        let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
        if (bottomOfWindow) {
          await this.fetchArticles();
        }
      }
    },
    async fetchArticles() {
      const {data} = await this.axios.get("/blog-articles/list/" + this.articles.length)
      this.articles = this.articles.concat([...data]);
    },
    async addArticle() {
      const {data} = await this.axios.put("/blog-articles", {
        user_id: this.$root.currentUser.userId
      }, {
        headers: {
          Authorization: this.$root.token.value
        }
      })
      const article_id = data.article_id;
      await this.$router.push({path: '/blog/publish-article/' + article_id})
    }
  }
}
</script>

<style scoped>
.shadow-blue {
  box-shadow: 0 12px 24px rgb(2 30 84 / 10%) !important;
}

.blog-tabs >>> .v-tabs-slider {
  height: 1px !important;
  border-radius: 0 !important;
}

.japanese-csv-pattern {
  background-image: url("data:image/svg+xml,<svg id='patternId' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><defs><pattern id='a' patternUnits='userSpaceOnUse' width='69.283' height='40' patternTransform='scale(2) rotate(0)'><rect x='0' y='0' width='100%' height='100%' fill='hsla(0,0%,100%,1)'/><path d='M46.189-20L57.736 0M46.189 20l11.547 20m-46.189 0l11.547 20M11.547 0l11.547 20m40.415 30H40.415M28.868 30H5.774m23.094-40H5.774m57.735 20H40.415m0 20L28.868 50m11.547-60L28.868 10m46.188 0L63.509 30M5.774 10L-5.773 30m75.056 10H46.189L34.64 20 46.19 0h23.094C73.13 6.667 76.98 13.333 80.83 20zM57.736 60H34.64L23.094 40l11.547-20h23.095c3.848 6.667 7.698 13.333 11.547 20L57.736 60zm0-40H34.64L23.094 0l11.547-20h23.095L69.283 0c-3.87 6.7-8.118 14.06-11.547 20zM34.64 60H11.547L0 40l11.547-20h23.094L46.19 40 34.64 60zm0-40H11.547L0 0l11.547-20h23.094L46.19 0 34.64 20zM23.094 40H0l-5.773-10-5.774-10L0 0h23.094l11.547 20-11.547 20z'  stroke-width='1' stroke='hsla(218, 44%, 49%, 1)' fill='none'/></pattern></defs><rect width='800%' height='800%' transform='translate(0,0)' fill='url(%23a)'/></svg>")
}
</style>
