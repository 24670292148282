<template>
  <v-hover v-slot:default="{ hover }" open-delay="50">
    <v-card :color="!hover ? 'white' : 'grey lighten-5'"
            @click="$router.push({path: '/blog/article/'+article.path})"
            style="cursor: pointer" :ripple="false"
            class="rounded-lg pa-6 shadow-blue">
      <div class="d-flex">
        <v-avatar size="24px" style="border: thin #eaeaea solid">
          <v-img height="100%" width="100%" contain
                 src="https://examenselectivitat.cat/img/icon_examenselectivitat.4720e451.png"></v-img>
        </v-avatar>
<!--        <span class="ml-2">{{article.user_name}}</span>-->
        <span class="ml-2">Examenselectivitat</span>
        <span class="mx-1 grey--text">·</span>
        <span class="grey--text">{{article.date}}</span>
      </div>

      <div class="d-flex">
        <div style="flex: 1">
          <div class="mt-3">
            <span class="title font-weight-bold" style="font-size: 26px !important;">{{article.title}}</span>
          </div>

          <div>
            <span>{{article.subtitle}}</span>
          </div>
        </div>

        <div class="d-flex" style="width: 200px;">
          <v-spacer></v-spacer>
          <v-img style="width: 120px !important; height: 120px !important; object-fit: cover !important; "
                 width="120" height="120"
                 class="rounded-lg"
                 :src="article.image"></v-img>
        </div>
      </div>

      <div class="d-flex mt-6">
        <v-chip v-for="tag in article.tags" class="mr-2">{{tag}}</v-chip>
      </div>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  name: "ArticleComponent",
  props: {
    article: {
      required: true
    }
  }
}
</script>

<style scoped>
.shadow-blue {
  box-shadow: 0 12px 24px rgb(2 30 84 / 10%) !important;
}
</style>
